.strong-number {
    color: #B68917;
    text-align: right;
    padding-left: 4px;
    padding-right: 4px;
}

.strong-number:hover {
    color: #9191A1;
    background-color: #B68917;
    border-radius: 6px;
}