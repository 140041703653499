.verse-number-and-first-word {
    white-space: nowrap;
    display: inline-block;
}

.verse-number {
    font-size: smaller;
    vertical-align: super;
    color: #9191A1;
}

.verse-left {
    color: #9191A1;
    width: 28px;
    align-self: flex-start;
    text-align: right;
    padding-right: 6px;
    margin-right: 3px;
}

.goodies {
    color: #B68917;
    font-weight: bold;
}

.verse-left:hover {
    background-color: #5E5E6E;
    border-radius: 6px;
}

.verse-reference {
    text-align: right;
    padding-left: 4px;
    padding-right: 4px;
}

.verse-reference:hover {
    background-color: #B68917;
    border-radius: 6px;
}

.highlight{
    border-bottom: 3px solid #ddac6a;
}

.woj {
    color: #FFAA89;
}

.verse-focus {
    /* border-bottom: 3px solid #b68917; */
}

.verse-before {
    filter: brightness(70%);
}

.verse-after {
    filter: brightness(70%);
}

.verse-notes-enter {
    max-height: 0;
    opacity: 0;
}

.verse-notes-active {
    max-height: 30px;
    opacity: 1;
    transition: all 500ms;
}

.verse-notes-exit {
    max-height: 30px;
    opacity: 1;
}

.verse-notes-exit-active {
    max-height: 0;
    opacity: 0;
    transition: all 500ms;
}