body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.notes {
    padding-bottom: 12px;
    padding-left: 12px;
}

.notes-with-bar {
    margin-left: 12px;
    padding-left: 12px;
    border-left: 4px solid #3F5280;
}

@keyframes highlightFade {
    from {
        fill: #D9A334; /* Brighter, more reddish highlight color */
    }
    to {
        fill: #B68917; /* Original color */
    }
}

.highlighted {
    animation: highlightFade 1s ease-out;
}

p {
    margin-block-start: 0;
    margin-block-end: 0.5rem;
}